import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

async function getData(api, url = '', data, auth_token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: api, // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth_token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  })
  const param = response.json()
  return param// parses JSON response into native JavaScript objects
}

async function getReports(api, url = '', data, auth_token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: api, // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth_token
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  }) 
  return response
}

const data = [
  {
    responsive_id: '',
    id: 1,
    avatar: '10.jpg',
    full_name: "Soil Testing",
    post: 'Alfie Solomon',
    email: 'alfie@thetimes.co.uk',
    city: 'Krasnosilka',
    start_date: '09/23/2016',
    salary: 'don2898',
    age: '61',
    experience: '1 Year',
    status: 2
  },
  {
    responsive_id: '',
    id: 2,
    avatar: '1.jpg',
    full_name: 'Ground Moisture',
    post: 'Don Johnson',
    email: 'don@yolasite.com',
    city: 'Hinigaran',
    start_date: '05/20/2018',
    salary: 'mike2876',
    age: '63',
    experience: '3 Years',
    status: 2
  },
  {
    responsive_id: '',
    id: 3,
    avatar: '9.jpg',
    full_name: 'Crop Quality Index',
    post: 'Mike Phillips',
    email: 'mike@tuttocitta.it',
    city: 'Golcowa',
    start_date: '03/24/2018',
    salary: 'sander2376',
    age: '66',
    experience: '6 Years',
    status: 5
  },
  {
    responsive_id: '',
    id: 4,
    avatar: '10.jpg',
    full_name: 'Grain Quality',
    post: 'Robin Williams',
    email: 'robin@google.co.jp',
    city: 'Paquera',
    start_date: '12/03/2017',
    salary: 'john2143',
    age: '22',
    experience: '2 Years',
    status: 2
  },
  {
    responsive_id: '',
    id: 5,
    avatar: '',
    full_name: 'Corn Yield',
    post: 'Roger Phillips',
    email: 'roger@gnu.org',
    city: 'Lucan',
    start_date: '08/25/2017',
    salary: 'pant4534',
    age: '33',
    experience: '3 Years',
    status: 2
  }
]


mock.onGet('/api/search-data').reply(async config => {
  const country = config.country ?? null
  const state = config.state ?? null
  const params = {skip: JSON.stringify(config.skip), limit: JSON.stringify(config.limit), startDate: config.startdate, endDate: config.enddate, type: config.type, keyword: config.value, userId: config.userId ?? null, extractType: config.extractType === "All" ? "" : config.extractType, country, state}
  const res = await getData('POST', `${process.env.REACT_APP_API_URL}/admin/search?sortBy=${config.sortBy}&sortOrder=${config.sortOrder}`, params, config.headers.Authorization)
  return [200, res]
})

mock.onGet('/api/reports').reply(async config => {
  const params = {startDate: config.startdate, endDate: config.enddate}
  const url = config.api_link
  const res = await getReports('POST', url, params, config.headers.Authorization)
  return [200, res]
})

mock.onGet('/api/datatables/data').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const { q = '', perPage = 10, page = 1 } = config
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.filter(
    item =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      item.full_name.toLowerCase().includes(queryLowered) ||
      item.post.toLowerCase().includes(queryLowered) ||
      item.email.toLowerCase().includes(queryLowered) ||
      item.age.toLowerCase().includes(queryLowered) ||
      item.salary.toLowerCase().includes(queryLowered) ||
      item.start_date.toLowerCase().includes(queryLowered)
  )
  /* eslint-enable  */

  return [
    200,
    {
      allData: data,
      invoices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})
